<template>
    <div>
		<div
			v-if="title && title.length"
			class="margin-b-24 align-items-flex-end
				bp-768:display-flex
				bp-1024:margin-b-32"
		>
			<h1
				class="section-title margin-b-8
					bp-768:margin-b-0 bp-768:margin-r-auto"
			>
				{{title}}
			</h1>
			<div>
				<span class="text-muted">Showing: </span>
				<span>{{ filtered_championships.length }}</span>
				<span>
					{{
						filtered_championships.length === 1
							? 'event'
							: 'events'
					}}
				</span>
			</div>
		</div>
		<div
			class="align-items-flex-end margin-b-24
				bp-1024:display-flex bp-1024:margin-b-32"
		>
			<ul
				class="reset-list display-flex margin-b-8 overflow-x-auto
					bp-1024:margin-r-16 bp-1024:margin-b-0"
			>
				<li
					class="button --toggle margin-r-8 flex-grow-1 justify-content-center
						bp-1024:flex-grow-0"
					:class="{ 'is-active': filter.division === division }"
					@click="filter_by_division(division)"
					v-for="division in divisions"
					:key="division"
				>
					{{ division }}
				</li>
			</ul>
			<div
				class="margin-b-8 flex-grow-1 position-relative
					bp-1024:margin-r-16 bp-1024:margin-b-0"
			>
				<select
					v-model="filter.season"
					class="reset bg-color-surface border-radius-rounded padding-l-32 padding-r-48 shadow-level-1 width-100"
					style="height:56px;line-height:56px;"
					name="filter-season"
					id="filter-season"
				>
					<option value="all">All Seasons</option>
					<option
						v-for="season in seasons"
						:value="season"
						:key="season"
					>
						{{ season }}
					</option>
				</select>
				<chevron-down-icon
					class="pointer-events-none position-absolute right-0 margin-r-16 top-50 transform-translateY-n50"
					size="24"
				></chevron-down-icon>
			</div>
			<div
				class="margin-b-8 flex-grow-1 position-relative
					bp-1024:margin-r-16 bp-1024:margin-b-0"
			>
				<select
					v-model="filter.sport"
					class="reset bg-color-surface border-radius-rounded padding-l-32 padding-r-48 shadow-level-1 width-100"
					style="height:56px;line-height:56px;"
					name="filter-sport"
					id="filter-sport"
				>
					<option value="all">All Sports</option>
					<option
						v-for="sport in sports"
						:value="sport.id"
						:key="sport.shortname"
					>
						{{ sport.title }}
					</option>
				</select>
				<chevron-down-icon
					class="pointer-events-none position-absolute right-0 margin-r-16 top-50 transform-translateY-n50"
					size="24"
				></chevron-down-icon>
			</div>
			<div
				class="margin-b-8 flex-grow-1 position-relative
					bp-1024:margin-r-16 bp-1024:margin-b-0"
			>
				<select
					v-model="filter.state"
					class="reset bg-color-surface border-radius-rounded padding-l-32 padding-r-48 shadow-level-1 width-100"
					style="height:56px;line-height:56px;"
					name="filter-state"
					id="filter-state"
				>
					<option value="all">All States</option>
					<option
						v-for="state in states"
						:value="state"
						:key="state"
					>
						{{ state }}
					</option>
				</select>
				<chevron-down-icon
					class="pointer-events-none position-absolute right-0 margin-r-16 top-50 transform-translateY-n50"
					size="24"
				></chevron-down-icon>
			</div>
			<div class="display-flex position-relative">
				<button
					class="reset bg-color-surface border-radius-rounded utility-flex-center shadow-level-1 cursor-pointer flex-grow-1 justify-content-center
						bp-1024:flex-grow-0"
					style="height:56px;width:56px;"
					:title="
						search_toggled
							? 'Close and cancel search'
							: 'Search by title'
					"
					:aria-label="
						search_toggled
							? 'Close and cancel search'
							: 'Search by title'
					"
					@click="toggle_search"
				>
					<search-icon
						size="21"
						v-if="!search_toggled"
					></search-icon>
					<x-icon v-else></x-icon>
				</button>
				<input
					v-model="filter.search"
					class="reset bg-color-surface border-radius-rounded padding-l-32 padding-r-48 position-absolute margin-t-8 top-100 right-0 shadow-level-1 z-index-2"
					:class="{ 'display-none': !search_toggled }"
					style="height:56px;width:350px;"
					type="text"
					name="search"
					ref="championship_search"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import { SearchIcon, XIcon, ChevronDownIcon } from 'vue-feather-icons';
import { get_unique_values_of_property, sort_by } from '@/helpers';

export default {
    name: 'ChampionshipsListFilter',

    components: {
        SearchIcon,
        XIcon,
        ChevronDownIcon,
    },

    props: {
        title: {
            type: String,
            default: null,
        },
		championships: {
			type: Array,
			required: true,
		},
		defaultSeason: {
			type: String,
			required: false,
			default: () => 'all'
		}
    },

	emits: ['updateFilter'],

    data: () => ({
		filter: {
			division: 'all',
			season: 'all',
			sport: 'all',
			state: 'all',
			search: '',
		},
		divisions: ['DI', 'DII', 'DIII', 'NC'],
		search_toggled: false,
    }),

	watch: {
		filter: {
			handler() {
				console.log(this.$route.name)
				this.$emit('updateFilter', this.filter_championships(this.championships))
			},
			deep: true,
		},

		seasons(newSeasons) {
			// Set the filter season to the season specified by the setting in the CMS (case-insensitive)
			// This is done in a watcher instead of created to avoid a race condition (and the watcher will only fire once so it's ok).
			const specifiedSeason = newSeasons.find(season => season.toLowerCase() == this.defaultSeason.toLowerCase());
			this.filter.season = specifiedSeason ? specifiedSeason : 'all';
		},
	},

	computed: {
		filtered_championships() {
			return this.filter_championships(this.championships)
		},

		seasons() {
			return get_unique_values_of_property(
				this.championships,
				'season',
			).sort();
		},

		sports() {
			let sports = get_unique_values_of_property(
				this.championships,
				'sport',
			);

			let unique_sports = new Set();

			return sports
				.reduce((acc, sport) => {
					if (!unique_sports.has(sport.id)) {
						unique_sports.add(sport.id, sport);
						acc.push(sport);
					}
					return acc;
				}, [])
				.sort(sort_by('title'));
		},

		states() {
			return get_unique_values_of_property(
				this.championships,
				'state',
			).sort();
		},
	},

	methods: {
		filter_championships(championships) {
			let _championships = championships.filter(c => {
				for (const key in this.filter) {
					if (key === 'search') {
						let search = this.filter.search.toLowerCase();
						let title = c.title.toLowerCase();
						if (search === '') continue;
						if (title.indexOf(search) === -1) return false;
						else continue;
					} else if (this.filter[key] === 'all') {
						continue;
					} else if (key === 'sport') {
						if (c.sport.id !== this.filter.sport) return false;
						else continue;
					}

					if (c[key] !== this.filter[key]) return false;
				}
				return true;
			});
			return _championships
				.sort(sort_by('featured'))
				.sort(sort_by('title'));
		},

		filter_by_division(division) {
			if (this.filter.division === division) {
				this.filter.division = 'all';
			} else {
				this.filter.division = division;
			}
		},

		toggle_search() {
			if (this.search_toggled === true) this.close_search();
			else this.open_search();
		},

		open_search() {
			this.search_toggled = true;
			this.$refs.championship_search.focus();
		},

		close_search() {
			this.search_toggled = false;
			this.clear_search();
		},

		clear_search() {
			this.filter.search = '';
		},
	},

}
</script>